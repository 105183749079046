import { useEmblaCarousel } from 'embla-carousel/react'

import Banner from '@/ts/interfaces/marketing/Banner'
import css from '@/views/home/scss/index.module.scss'

import Carousel from './includes/Carousel'
import Navigation from './includes/Navigation'
import Slogan from './includes/Slogan'

function Main({ banners }: { banners: Banner['records'] }) {
  if (!banners || banners.length <= 0) return null

  const [viewport, carousel] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    selectedClass: '',
    draggableClass: '',
    draggingClass: '',
    align: 'start',
    loop: true,
    inViewThreshold: 1,
  })

  return (
    <main className="flex flex-col w-full space-y-6 xl:space-y-0">
      <div className="flex flex-col space-y-8 xl:flex-row xl:space-y-0 xl:space-x-2 xl:pl-12   2xl:pl-[calc((100%-1680px+8rem)/2)] 3xl:pl-[calc((100%-1680px)/2)]">
        <Slogan />
        <div className="relative w-full overflow-hidden xl:pb-20">
          <Carousel ref={viewport} banners={banners} />
          <div className={css['sticky-ball']} />
        </div>
      </div>
      <Navigation carousel={carousel} totalItems={banners.length} />
    </main>
  )
}

export default Main
