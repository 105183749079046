import Head from 'next/head'

import { useMediaQuery } from '@/hooks/useMediaQuery'

import extractSlidersData from './functions/extractSlidersData'
import FansiCollections from './includes/FansiCollections'
import FeaturedCreators from './includes/FeaturedCreators'
import FeaturedProducts from './includes/FeaturedProducts'
import Main from './includes/Main'
import ProductMarketingSlider from './includes/MarketingSlider'
import { HomeViewProps } from './ts'

function HomeView({ banners, sliders }: HomeViewProps) {
  const slidersData = extractSlidersData(sliders)
  const isBreakpoint = useMediaQuery(480)

  return (
    <div>
      <Head>
        <meta
          name="description"
          content="Najlepsze produkty od Twoich ulubionych influencerów: e-booki, audiobooki, planery, książki, kosmetyki, personalizowane video, kolekcje ubrań i gadżety. Kupuj i sprzedawaj."
        />
      </Head>
      {isBreakpoint ? (
        <Main banners={banners.mobile.records} />
      ) : (
        <Main banners={banners.desktop.records} />
      )}
      <FeaturedProducts products={slidersData.featured_products} />
      <ProductMarketingSlider products={slidersData.digital} />
      <FeaturedCreators creators={slidersData.creators} />
      <FansiCollections products={slidersData.fansi_collection} />
      <ProductMarketingSlider products={slidersData.fansi_video} />
    </div>
  )
}

export default HomeView
