import formatPrice from '@/helpers/formatPrice'
import ProductPrice from '@/ts/interfaces/product/ProductPrice'

function PriceTag({ price }: { price?: ProductPrice }) {
  if (!price) return null

  const { currency, value } = price

  return (
    <div className="absolute right-0 flex items-center max-w-full px-2 py-1 bg-white rounded-l-sm shadow-sm bottom-6 md:px-4">
      <span className="text-sm font-medium md:text-base">
        {formatPrice(value, currency)}
      </span>
    </div>
  )
}

export default PriceTag
