import useTranslation from 'next-translate/useTranslation'

import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'
import GROUPS from '@/organisms/Footer/data/groups'

function GroupNav() {
  const { t } = useTranslation('global')

  return (
    <>
      {GROUPS.map(({ heading, links }) => (
        <div key={heading} className="space-y-4" style={{ gridArea: heading }}>
          <h3 className="font-bold text-white">{t(heading)}</h3>
          <ul className="space-y-4">
            {links.map(({ name, url, icon }) => (
              <li key={name}>
                <Link
                  className="flex items-center text-sm transition-colors hover:text-sbtviolet-600"
                  href={url}
                >
                  <span>{t(name)}</span>
                  {icon && <Icon className="w-5 ml-2 md:h-8" name={icon} />}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}

export default GroupNav
