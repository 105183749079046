import clsx from 'clsx'

import Arrow from '../Arrow/Arrow'
import Link from '../Link'
import { GoProps } from './ts'

function Go({ className, href, text }: GoProps) {
  return (
    <Link
      className={clsx(
        'flex items-center group space-x-2 transition-colors',
        className
      )}
      href={href}
    >
      <span>{text}</span>
      <Arrow
        className="block w-6 transition-transform transform translate-x-0 group-hover:translate-x-1"
        direction="right"
        variant="primary"
      />
    </Link>
  )
}

export default Go
