import useTranslation from 'next-translate/useTranslation'

function Slogan() {
  const { t } = useTranslation('home')

  return (
    <div className="flex-shrink-0 px-4 xl:pt-20 2xl:pt-28 2sm:px-8 xl:px-0">
      <h1 className="max-w-[480px] text-sm font-medium text-black sm:text-lg text-opacity-80">
        {t('main_caption_text')}
      </h1>
      <p className="mt-4 text-lg font-medium sm:text-2xl xl:max-w-[400px] md:text-3xl 2xl:text-4xl 2xl:max-w-md">
        Influencers for Well-being & Good living
      </p>
    </div>
  )
}

export default Slogan
