import useTranslation from 'next-translate/useTranslation'

import Go from '@/atoms/Go'

import { RedirectLinkProps } from '../ts'

function RedirectLink({ basePath, nickname }: RedirectLinkProps) {
  const { t } = useTranslation('global')
  const url = nickname ? basePath + `?creator=${nickname}` : basePath

  return (
    <Go
      className="flex-shrink-0 lowercase"
      href={url}
      text={t('see_all_products')}
    />
  )
}

export default RedirectLink
