import { EmblaCarouselType } from 'embla-carousel/embla-carousel-vanilla'
import { useCallback, useEffect, useState } from 'react'

import useRecursiveTimeout from './useRecursiveTimeout'

function useCarouselState(carousel?: EmblaCarouselType) {
  const [currentSlide, setCurrentSlide] = useState(1)

  const autoplay = useCallback(() => {
    if (!carousel) return
    if (carousel.canScrollNext()) {
      carousel.scrollNext()
      setCurrentSlide(carousel.selectedScrollSnap() + 1)
    } else {
      carousel.scrollTo(0)
      setCurrentSlide(1)
    }
  }, [carousel])

  const { play, stop } = useRecursiveTimeout(autoplay, 4000)

  const scrollNext = useCallback(() => {
    if (!carousel) return
    carousel.scrollNext()
    setCurrentSlide(carousel.selectedScrollSnap() + 1)
    stop()
  }, [carousel, stop])

  const scrollPrevious = useCallback(() => {
    if (!carousel) return
    carousel.scrollPrev()
    setCurrentSlide(carousel.selectedScrollSnap() + 1)
    stop()
  }, [carousel, stop])

  const onSelect = useCallback(() => {
    if (!carousel) return
    setCurrentSlide(carousel.selectedScrollSnap() + 1)
  }, [carousel])

  useEffect(() => {
    if (!carousel) return
    carousel.on('pointerDown', stop)
  }, [carousel, stop])

  useEffect(() => {
    if (!carousel) return
    carousel.on('select', onSelect)
    onSelect()
  }, [carousel, onSelect])

  useEffect(() => play(), [play])

  return { scrollPrevious, scrollNext, currentSlide }
}

export default useCarouselState
