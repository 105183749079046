import { IconName } from '@/ts/Global'

const GROUPS: Group[] = [
  {
    heading: 'fansi',
    links: [
      {
        name: 'about_us',
        url: '/about',
      },
      {
        name: 'for_creators',
        url: '/for-creators',
      },
      {
        name: 'for_brands',
        url: '/for-brands',
      },
      {
        name: 'contact',
        url: '/contact',
      },
    ],
  },
  {
    heading: 'customer_service',
    links: [
      {
        name: 'help_center',
        url: '/help',
      },
      {
        name: 'methods_and_time_of_delivery',
        url: '/help/delivery',
      },
      {
        name: 'returns_and_complaints',
        url: '/help/returns-and-complaints',
      },
      {
        name: 'payment_methods',
        url: '/help/payments',
      },
    ],
  },
  {
    heading: 'have_questions?',
    links: [
      {
        name: 'write_to_us',
        url: '/contact',
        icon: 'send',
      },
    ],
  },
]

interface Group {
  heading: string
  links: {
    name: string
    url: string
    icon?: IconName
  }[]
}

export default GROUPS
