import useTranslation from 'next-translate/useTranslation'

import Go from '@/atoms/Go'
import CreatorSlider from '@/organisms/CreatorSlider'

import { FeaturedCreatorsProps } from '../ts'

function FeaturedCreators({ creators }: FeaturedCreatorsProps) {
  if (!creators || creators.length <= 0) return null

  const { t } = useTranslation('global')

  return (
    <div className="flex flex-col mt-16 space-y-6 md:space-y-12">
      <div className="flex flex-col justify-between space-y-2 2sm:flex-row 2sm:space-y-0 box">
        <h2 className="text-xl font-medium md:text-2xl">{t('creators')}</h2>
        {creators.length > 10 && (
          <Go
            className="flex-shrink-0 lowercase hover:text-violet-150"
            href="/creators"
            text={t('see_all_creators')}
          />
        )}
      </div>
      <CreatorSlider creators={creators} />
    </div>
  )
}

export default FeaturedCreators
