import CreatorCard from '@/molecules/CreatorCard'
import Slider from '@/organisms/Slider'

import { CreatorSliderProps } from './ts'

function CreatorSlider({ className, creators }: CreatorSliderProps) {
  if (!creators || creators.length <= 0) return null

  return (
    <>
      <Slider className={className}>
        {creators.map((creator) => (
          <article
            className="flex-shrink-0 w-full max-w-[220px] xl:max-w-[260px]"
            key={creator.id}
          >
            <CreatorCard creator={creator} />
          </article>
        ))}
      </Slider>
    </>
  )
}

export default CreatorSlider
