import useTranslation from 'next-translate/useTranslation'

import ProductSlider from '@/organisms/ProductSlider'

import css from '../scss/index.module.scss'
import { FeaturedProductProps } from '../ts'

function FeaturedProducts({ products }: FeaturedProductProps) {
  if (!products || products.length <= 0) return null

  const { t } = useTranslation('global')

  return (
    <div className={css['featured-products']}>
      <h2 className="mb-8 text-xl font-medium md:text-2xl box">
        {t('featured')}
      </h2>
      <ProductSlider asFeatured products={products} />
    </div>
  )
}

export default FeaturedProducts
