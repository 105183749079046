const DOCS: Doc[] = [
  {
    name: 'platform_regulations',
    url: '/docs/terms.pdf',
  },
  {
    name: 'privacy_policy',
    url: '/docs/privacy-policy.pdf',
  },
]

interface Doc {
  name: string
  url: string
}

export default DOCS
