import Image from 'next/image'
import { forwardRef } from 'react'

import generateBlurDataURL from '@/helpers/generateBlurDataURL'
import { CarouselProps } from '@/views/home/ts'

const Carousel = forwardRef<HTMLDivElement, CarouselProps>((props, ref) => {
  const blur = generateBlurDataURL(400, 400)

  return (
    <div ref={ref} className="pl-4 sm:pl-8 xl:pl-0">
      <div className="flex w-full max-w-[calc(100vw-40px)]">
        {props.banners.map((banner) => (
          <a
            key={banner.id}
            href={banner.url || '#'}
            className="relative block flex-shrink-0 box-border w-full overflow-hidden rounded-lg pt-[100%] 2sm:h-[320px] 2sm:pt-[0] mx-2 xl:mx-3 2sm:max-w-[680px] xl:max-w-[650px] 2xl:h-[400px] 2xl:max-w-[800px]"
          >
            <Image
              alt="Fansi Hero"
              src={banner.src}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={blur}
              priority
              loading="eager"
            />
          </a>
        ))}
      </div>
    </div>
  )
})

export default Carousel
