import ProductSlider from '@/organisms/ProductSlider'
import SliderHeader from '@/views/common/SliderHeader'

import { FeaturedProductProps } from '../ts'

function FansiCollections({ products }: FeaturedProductProps) {
  if (!products || products.length <= 0) return null

  const count = products.length
  const type = products[0].type

  return (
    <div className="relative flex flex-col mt-12 overflow-hidden xl:mt-20 bg-[#827cdb] py-8 text-white">
      <SliderHeader count={count} type={type} />
      <ProductSlider products={products} />
    </div>
  )
}

export default FansiCollections
