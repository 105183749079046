
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetServerSideProps } from "next"
import Head from "next/head"

import getBanners from "@/graphql/requests/getBanners"
import getMarketingSliders from "@/graphql/requests/getMarketingSliders"
import SEO from "@/meta/SEO"
import Default from "@/templates/Default"
import Banner from "@/ts/interfaces/marketing/Banner"
import Sliders from "@/ts/interfaces/marketing/Sliders"
import HomeView from "@/views/home"

function HomePage({
  staticBanners: { desktop, mobile },
  staticSliders,
}: HomePageProps) {
  return (
    <>
      <Head>
        <title>fansi.pl</title>
        <SEO description="Najlepsze produkty od Twoich ulubionych influencerów: e-booki, audiobooki, planery, książki, kosmetyki, personalizowane video, kolekcje ubrań i gadżety. Kupuj i sprzedawaj." />
      </Head>
      <HomeView
        banners={{ desktop: desktop[0], mobile: mobile[0] }}
        sliders={staticSliders}
      />
    </>
  )
}

HomePage.Layout = Default

 const _getServerSideProps: GetServerSideProps = async () => {
  const staticBanners = await getBanners()
  const staticSliders = await getMarketingSliders()

  return { props: { staticBanners, staticSliders } }
}
interface HomePageProps {
  staticBanners: {
    desktop: Banner[]
    mobile: Banner[]
  }
  staticSliders: Sliders
}

export default HomePage


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  