import useTranslation from 'next-translate/useTranslation'

import FansiMark from '@/atoms/FansiMark'
import PRODUCT_AS_FANSI_KIND from '@/constants/productAsFansiKind'
import PRODUCT_AS_URL from '@/constants/productAsUrl'

import RedirectLink from './includes/RedirectLink'
import { SliderHeaderProps } from './ts'

function SliderHeader(props: SliderHeaderProps) {
  const { t } = useTranslation('global')
  const { type, count, nickname, asCreator = false } = props

  const kind = PRODUCT_AS_FANSI_KIND[type]
  const basePath = `/products/${PRODUCT_AS_URL[type]}`
  const tPath = asCreator ? 'creators:fansi_desc' : 'fansi_desc'

  return (
    <>
      <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 md:space-x-12 md:items-end md:justify-between paded-layout">
        <div className="flex flex-col w-full space-y-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="flex items-center space-x-4">
              <span className="text-xl font-medium md:text-2xl">{t(kind)}</span>
              <FansiMark kind={kind} />
            </div>
            {count > 10 && (
              <RedirectLink basePath={basePath} nickname={nickname} />
            )}
          </div>
          <p className="max-w-lg text-sm md:max-w-2xl text-opacity-60 md:text-base">
            {t(`${tPath}.${kind}`)}
          </p>
        </div>
      </div>
    </>
  )
}

export default SliderHeader
