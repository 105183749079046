function SEO({ description }: SEOProps) {
  return (
    <>
      {/* <meta name="title" content="fansi.pl - Influencers are the new brands" /> */}
      <meta name="description" content={description} />
    </>
  )
}

interface SEOProps {
  description?: string
}

export default SEO
