import clsx from 'clsx'

import css from '../../css/index.module.css'
import GroupBrandbuddies from './includes/GroupBrandbuddies'
import GroupNav from './includes/GroupNav'
import GroupSocialMedia from './includes/GroupSocialMedia'

function FooterGroup() {
  return (
    <div className="box">
      <div
        className={clsx(
          css.group,
          'lg:flex relative xl:px-[70px] lg:mx-auto lg:items-end grid gap-y-10 gap-x-4 lg:gap-y-0 lg:flex-row 2sm:px-8 px-4 lg:justify-between'
        )}
      >
        <GroupNav />
        <GroupSocialMedia />
        <GroupBrandbuddies />
      </div>
    </div>
  )
}

export default FooterGroup
