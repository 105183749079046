import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

function GroupBrandbuddies() {
  const { t } = useTranslation('global')

  return (
    <div className="my-auto" style={{ gridArea: 'brandbuddies_group' }}>
      <h3 className="mb-2 font-bold text-white">{t('brandbuddies_group')}</h3>
      <div className="max-w-xs">
        <a href="https://www.brandbuddies.pl/">
          <Image
            src="/images/for-brands/brandbuddies.svg"
            width={856}
            height={90}
            quality={75}
            loading="eager"
          />
        </a>
      </div>
    </div>
  )
}

export default GroupBrandbuddies
