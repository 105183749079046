import useCarouselState from '@/views/home/functions/useCarouselState'
import { NavigationProps } from '@/views/home/ts'

import Control from './includes/Control'

function Navigation({ carousel, totalItems }: NavigationProps) {
  const state = useCarouselState(carousel)
  const { currentSlide, scrollPrevious, scrollNext } = state

  return (
    <div className="flex items-center self-end mr-8 space-x-6 xl:mr-16 2xl:mr-28">
      <Control direction="left" onClick={scrollPrevious} />
      <div
        className="flex justify-center text-lg text-violet-150 tabular-nums"
        style={{ wordSpacing: 10 }}
      >
        <span className="block mr-2.5 w-4">{currentSlide}</span> / {totalItems}
      </div>
      <Control direction="right" onClick={scrollNext} />
    </div>
  )
}

export default Navigation
