import Creator from '@/ts/Creator'
import Sliders from '@/ts/interfaces/marketing/Sliders'
import Product from '@/ts/Product'

function extractSlidersData(data: Sliders) {
  const sliders = Object.entries(data).reduce(
    (sliders: Record<string, Creator[] & Product[]>, [kind, data]) => {
      sliders[kind] = data?.[0].records.map(
        (record: Record<'creator' | 'product', Creator[] & Product[]>) => {
          if (record?.creator) return record.creator
          return record.product
        }
      )
      return sliders
    },
    {}
  )

  return sliders
}

export default extractSlidersData
