import useTranslation from 'next-translate/useTranslation'

import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'
import SOCIAL_MEDIA from '@/organisms/Footer/data/socialMedia'

function GroupSocialMedia() {
  const { t } = useTranslation('global')

  return (
    <div className="space-y-4" style={{ gridArea: 'follow_us' }}>
      <h3 className="font-bold text-white">{t('follow_us')}</h3>
      <ul className="flex items-center space-x-3">
        {SOCIAL_MEDIA.map(({ name, url }) => (
          <li key={name}>
            <Link href={url} target="_blank">
              <Icon
                className="w-8 h-8 p-2 transition-colors rounded-md hover:bg-sbtviolet-200/100 bg-sbtviolet-200/50 text-sbtviolet-600"
                name={name}
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default GroupSocialMedia
