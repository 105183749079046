import { useRouter } from 'next/router'

import useFrozenBody from '@/hooks/useFrozenBody'
import Footer from '@/organisms/Footer'
import Header from '@/organisms/Header'
import Motion from '@/organisms/Motion'
import useModalStore from '@/store/slices/modal'

function Default({ children }: Template) {
  const { name } = useModalStore()
  const { route } = useRouter()

  const freezed = typeof name === 'string'

  useFrozenBody(freezed)

  return (
    <>
      <Motion
        className="flex flex-col flex-grow h-full"
        transition={{ delay: 0.2 }}
      >
        <Header />
        <Motion
          key={route}
          className="flex flex-col flex-grow"
          transition={{ delay: 0.2 }}
        >
          <div className="flex-grow pt-3 pb-12 md:pt-6 xl:pt-10">
            {children}
          </div>
          <Footer />
        </Motion>
      </Motion>
    </>
  )
}

export default Default
