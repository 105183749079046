import clsx from 'clsx'

import ProductSlider from '@/organisms/ProductSlider'
import SliderHeader from '@/views/common/SliderHeader'

import { MarketingSliderProps } from '../ts'

function MarketingSlider(props: MarketingSliderProps) {
  const { products } = props

  if (!products || products.length <= 0) return null

  const count = products.length
  const type = products[0].type

  return (
    <div
      className={
        (clsx('flex flex-col mt-12 space-y-6 md:space-y-8 xl:mt-20'),
        type === 'Product::Digital'
          ? 'bg-[#85cec2] text-white py-8'
          : 'bg-white py-8')
      }
    >
      <SliderHeader count={count} type={type} />
      <ProductSlider products={products} />
    </div>
  )
}

export default MarketingSlider
