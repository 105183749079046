import { memo } from 'react'

import Motion from '../Motion'
import FooterBottomBar from './includes/FooterBottomBar'
import FooterGroup from './includes/FooterGroup'

function Footer({ onlyBar = false }: FooterProps) {
  return (
    <>
      {/* <meta name="robots" content="noindex,nofollow" /> */}
      <Motion
        as="footer"
        className="relative z-10 space-y-12 xl:space-y-8 pt-8 bg-[#85cfc3] text-white font-medium"
        transition={{ delay: 0.4 }}
      >
        {!onlyBar && (
          <>
            <FooterGroup />
            {/* <FooterComposition /> */}
          </>
        )}
        <FooterBottomBar onlyBar={onlyBar} />
      </Motion>
    </>
  )
}

interface FooterProps {
  onlyBar?: boolean
}

export default memo(Footer)
