import clsx from 'clsx'
import Image from 'next/image'

import generateBlurDataURL from '@/helpers/generateBlurDataURL'

import css from '../scss/index.module.scss'
import { AvatarProps } from '../ts'
import PriceTag from './PriceTag'

function Avatar({ src, nickname, price, layout }: AvatarProps) {
  const blur = generateBlurDataURL(300, 260)

  return (
    <figure
      className={clsx(css['creator-avatar'], {
        [css['creator-avatar--flex']]: layout === 'flex',
        [css['creator-avatar--grid']]: layout === 'grid',
      })}
    >
      <Image
        alt={nickname}
        src={src.replace(/\/([^/]+)$/, '/cover_$1')}
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        blurDataURL={blur}
        quality={75}
        loading="eager"
      />
      <PriceTag price={price} />
    </figure>
  )
}

export default Avatar
