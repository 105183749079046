import { useEffect } from 'react'

function useFrozenBody(isFrozen: boolean | null): void {
  useEffect(() => {
    if (isFrozen) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth || 0
      const scrollPosition = window.pageYOffset

      document.body.classList.add('frozen')
      document.body.style.paddingInlineEnd = `${scrollbarWidth}px`

      return () => {
        window.scrollTo({ top: scrollPosition })
        setTimeout(() => {
          document.body.classList.remove('frozen')
          document.body.style.paddingInlineEnd = '0px'
        }, 300)
      }
    }
  }, [isFrozen])
}

export default useFrozenBody
