import { createMedia } from '@artsy/fresnel'

const MediaQueries = createMedia({
  breakpoints: {
    '3xs': 320,
    '2xs': 375,
    xs: 420,
    '2sm': 480,
    sm: 576,
    '2md': 640,
    md: 768,
    '2lg': 924,
    lg: 1024,
    xl: 1200,
    '2xl': 1680,
    '3xl': 1800,
  },
})

export const mediaStyles = MediaQueries.createMediaStyle()
export const { Media, MediaContextProvider } = MediaQueries
