import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'
import DOCS from '@/organisms/Footer/data/docs'

function FooterBottomBar({ onlyBar }: FooterBottomBarProps) {
  const { t } = useTranslation('global')

  return (
    <div className="bg-[#837ddd]">
      <div className="box">
        <div className="relative py-6 pr-8">
          <ul
            className={clsx(
              'flex flex-wrap items-center md:justify-center gap-4 px-4 text-xs font-medium text-white 2sm:px-8 2lg:text-sm 2lg:gap-x-10',
              {
                'lg:w-[calc(100%-220px)] xl:w-[calc(100%-480px)] 2xl:max-w-screen-2xl mx-auto': !onlyBar,
              }
            )}
          >
            <li className="order-last sm:order-first sm:w-auto">
              {t('copyrights')}
            </li>
            {DOCS.map(({ name, url }) => (
              <li key={name}>
                <Link className="hover:text-white/60" href={url}>
                  {t(name)}
                </Link>
              </li>
            ))}
          </ul>
          <Icon
            name="footer_square"
            className="absolute w-[64px] h-[64px] top-[-32px] right-0 md:right-[calc(100%-64px)]"
          />
        </div>
      </div>
    </div>
  )
}

interface FooterBottomBarProps {
  onlyBar: boolean
}

export default FooterBottomBar
