import { IconName } from '@/ts/Global'

const SOCIAL_MEDIA: SocialMedia[] = [
  {
    name: 'facebook_outline',
    url: 'https://www.facebook.com/platformafansi',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/fansi.pl',
  },
  {
    name: 'tiktok',
    url: 'https://www.tiktok.com/@homeofinfluencers?lang=pl-PL',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCLp5F-Cn0vCy5wtHHLgteGg',
  },
]

interface SocialMedia {
  name: IconName
  url: string
}

export default SOCIAL_MEDIA
