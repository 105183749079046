import Link from '@/atoms/Link'

import Avatar from './includes/Avatar'
import { CreatorCardProps } from './ts'

function CreatorCard(props: CreatorCardProps) {
  const { creator, price, productSlug, layout = 'flex' } = props
  const { avatar, nickname, slug } = creator
  const href = productSlug ? `/products/${productSlug}` : `/creators/${slug}`

  return (
    <Link className="flex flex-col group" href={href}>
      <Avatar nickname={nickname} src={avatar} price={price} layout={layout} />
      <span className="mt-4 text-sm font-medium leading-tight truncate md:text-base group-hover:underline">
        {nickname}
      </span>
    </Link>
  )
}

export default CreatorCard
