import Arrow from '@/atoms/Arrow/Arrow'
import { Media } from '@/templates/Media/Media'
import { ControlProps } from '@/views/home/ts'

function Control({ direction, onClick }: ControlProps) {
  return (
    <Media className="flex items-start" greaterThanOrEqual="lg">
      <button
        className="w-6 focus:outline-none text-violet-225"
        onClick={onClick}
      >
        <Arrow direction={direction} variant="secondary" />
      </button>
    </Media>
  )
}

export default Control
